import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic11 from '../assets/images/pic11.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Thank you for your donation</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Thank you for your donation.</h1>
                    </header>
                    <p>Your donation will go directly to teachers.</p>
                    </div>
            </section>
        </div>

    </Layout>
)

export default Generic